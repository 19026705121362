@import '@src/core/ant-custom.css';

:root {
    --primary-color: #ffdd2d;
    --main-green: #1e513c;
    --main-red: #e01039;
    --cargo-color: #075179;
}

body {
    margin: 0;
}

.logo {
    height: 64px;
    display: table;
    width: 100%;
}

.sub-logo {
    display: table-row;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--primary-color);
}

.logout {
    font-family: 'Rubik', Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.user {
    font-family: 'Rubik', Arial, sans-serif;
    font-size: 19px;
    line-height: 22.27px;
}

.main-header {
    padding: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 2px;
}

.main-content {
    padding: 15px 15px 0;
    overflow: auto;
}

.processing {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.45);
}

.processing-area {
    background-color: #1e513c;
    padding: 20px;
}

.user-account-info {
    background-color: #1e513c;
    color: #ffffff;
    padding: 0 20px;
    cursor: default;
}

.trackingUrl {
    margin-left: 10px;
    color: var(--primary-color);
}

.mainWarehouseTag {
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 0px 4px;
    font-size: 11px;
    width: fit-content;
    margin: auto;
    border: 1px solid rgb(62, 70, 87);
}

.transitWarehouseTag {
    background-color: rgb(62, 70, 87);
    color: rgb(255, 255, 255);
    font-weight: 700;
    padding: 0px 4px;
    font-size: 11px;
    width: fit-content;
    margin: auto;
}

.country-form-item {
    margin: 0 0 4px 0;
    min-height: 22px;
    height: 22px;
    text-align: left;
}

.delivery-option {
    color: var(--primary-color);
    margin: -5px;
}

.icon-background {
    border: 1px solid;
    padding: 7px;
    border-radius: 50%;
    background: #1e513c !important;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.editable-cell-value-wrap:hover {
    background: #ffffff;
}

.flight-price {
    background: #e6f1f6;
    padding: 2px 2px 2px 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.flight-truck-price {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #e6f1f6;
    padding: 2px 2px 2px 5px;
}

.truck-price {
    padding: 2px 2px 2px 5px;
}

.price-value {
    margin-left: 10px;
    font-weight: 600;
}

.santa-hat-auth {
    -webkit-transform: rotate(22deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    transform: rotate(22deg);
    top: -4px;
    left: 57px;
    width: 40px;
    height: 40px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url(./event-icons/santa-corner-hat.png);
}

.santa-hat-main-logo {
    -webkit-transform: rotate(22deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    transform: rotate(22deg);
    margin-top: 1px;
    left: 28px;
    width: 40px;
    height: 40px;
    margin-right: -40px;
    position: sticky;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url(./event-icons/santa-corner-hat.png);
}

.santa-hat-mini-logo {
    -webkit-transform: rotate(22deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    transform: rotate(22deg);
    margin-top: 2px;
    left: 5px;
    width: 40px;
    height: 40px;
    margin-right: -40px;
    position: sticky;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url(./event-icons/santa-corner-hat.png);
}

.comment-btn {
    color: #ffa500 !important;
}

.no-comment-btn {
    color: #3e4657 !important;
}

.active-dot {
    height: 9px;
    width: 9px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}

.inactive-dot {
    height: 9px;
    width: 9px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

.lbl {
    width: 80px;
    float: left;
    margin-left: -22px;
    transform: rotate(-50deg);
    -ms-transform: rotate(-50deg); /* IE 9 */
    -webkit-transform: rotate(-50deg); /* Opera, Chrome, and Safari */
}
.inp {
    float: left;
    margin-top: 50px;
}
