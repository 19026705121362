.auth {
    display: table;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background: radial-gradient(circle, rgba(51, 116, 89, 1) 0%, rgba(30, 81, 60, 1) 34%, rgba(11, 29, 22, 1) 100%);
}

.auth-container {
    display: table-cell;
    vertical-align: middle;
}

.auth-logo {
    margin: auto;
    text-align: center;
}

.auth-whs-name {
    font-size: 35px;
    font-family: 'Rubik', Arial, sans-serif;
    line-height: 2.6;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--primary-color);
    text-align: center;
}

.auth-form {
    width: 350px;
    position: relative;
    margin: auto;
    font-weight: 500;
}

.auth-form-title {
    align-items: center;
    text-align: center;
    font-family: 'Rubik', Arial, sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: var(--primary-color);
}

.auth-form-item {
    margin-bottom: 24px;
}

.auth-form-item-input {
    background-color: #ffffff;
}

.ant-input-affix-wrapper-disabled.auth-form-item-input {
    background-color: rgba(249, 249, 249, 0.75) !important;
}

.auth-form-btn {
    margin-top: 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
}

.auth-qr-container {
    position: absolute;
    right: 20px;
    top: 35px;
    display: flex;
    text-align: center;
}

.auth-support-container {
    position: absolute;
    left: 20px;
    top: 35px;
    display: flex;
    text-align: center;
    color: #ffffff;
}

.auth-support {
    font-size: 22px;
    font-family: 'Rubik', Arial, sans-serif;
    line-height: 1.8;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin: 20px;
}

.auth-support-phone {
    font-size: 20px;
}
