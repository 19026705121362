/*  */

/* dropdown button */
.toolbar-dropdown-control {
    font-size: 14px;
    height: 32px !important;
    padding: 4px 15px;
    border-radius: 6px !important;
    align-items: center;
    font-weight: 400;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    touch-action: manipulation;
    background: #ffffff;
    border-color: #d9d9d9;
    color: #000000;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    width: 32px !important;
    padding-inline: 0;
}

.toolbar-dropdown-control:hover {
    border-color: #74a3c7 !important;
    color: #74a3c7 !important;
}

.toolbar-dropdown-control:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
}

.toolbar-dropdown-control-inner-button:not([disabled]) .anticon-down {
    font-size: 12px;
}

.toolbar-dropdown-control-inner-button:not([disabled]) .anticon-up {
    font-size: 12px;
}

/* dropdown with button */
.toolbar-dropdown-button-control {
    font-size: 16px;
}

.toolbar-dropdown-button-control .ant-btn:not([disabled]) .ant-btn-icon .anticon {
    font-size: 12px;
}
