.popup-notification {
    .title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .message {
        white-space: break-spaces;
        font-style: italic;
        max-width: 500px;
        overflow: auto;
        max-height: 200px;
    }
}
