.area {
    height: 20px;
    width: 50px;
    margin: auto;
}

.shipment {
    background-color: #00e500;
}

.acceptance {
    background-color: yellow;
}
