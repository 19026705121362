.bill table {
    width: 100%;
    border-spacing: 0 !important;
    border: 1px solid black;
    text-align: center;
}

.bill th,
.bill td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
}

.bill-header,
.bill-sub-header {
    background-color: var(--primary-color);
    border-left: 2px solid black;
    border-top: 2px solid black;
    border-right: 2px solid black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px 10px;
    font-weight: 600;
}
